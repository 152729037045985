import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import cx from "classnames";

import { Container } from '../container';
import styles from './how-it-work.module.css';
import icon1 from './icons/icon1.svg';
import icon2 from './icons/icon2.svg';
import icon3 from './icons/icon3.svg';
import icon4 from './icons/icon4.svg';
import icon5 from './icons/icon5.svg';
import vector1 from './icons/Vector-1.svg';
import vector2 from './icons/Vector-6.svg';
import vector3 from './icons/Vector-7.svg';
import vector4 from './icons/VectorMob1.svg';
import vector5 from './icons/VectorMob2.svg';

export const HowItWork2 = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.section}>
      <Container isWide={true}>
        <h3 className="fancyUnderlineText sectionTitleNew text-center">
          <Trans i18nKey="home.howItWorks.title2">
          Wie <span>funktioniert es?</span>
          </Trans>
        </h3>
        <div className={styles.sectionList}>

          <div className={styles.sectionListItem}>
            <div className={styles.sectionListText}>
              <div className={styles.title}>
                <div className={styles.sectionListNumber}>
                  <span>1</span>
                </div>
                {t("home.howItWorks.option1")}
              </div>
              <div className={styles.description}>
                {t("home.howItWorks.option1Description")}
              </div>
            </div>
            <div className={styles.sectionListImgs}>
              <img src={vector4} className={styles.iconMob} alt="icon" />
              <img src={vector1} className={styles.icon} alt="icon" />
              <img src={icon1} className={styles.icon} alt="icon" />
              <img src={vector2} className={styles.iconDesc} alt="icon" />              
            </div>
          </div>

          <div className={styles.sectionListItem}>
            <div className={styles.sectionListText}>
              <div className={styles.title}>
                <div className={styles.sectionListNumber}>
                  <span>2</span>
                </div>
                {t("home.howItWorks.option2")}
              </div>
              <div className={styles.description}>
                {t("home.howItWorks.option2Description")}
              </div>
            </div>
            <div className={styles.sectionListImgs}>
              <img src={vector5} className={styles.iconMob} alt="icon" />
              <img src={vector1} className={styles.icon} alt="icon" />
              <img src={icon2} className={styles.icon} alt="icon" />
              <img src={vector3} className={cx(styles.iconDesc, styles.iconDescLeft)} alt="icon"/>
            </div>
          </div>

          <div className={styles.sectionListItem}>
            <div className={styles.sectionListText}>
              <div className={styles.title}>
                <div className={styles.sectionListNumber}>
                  <span>3</span>
                </div>
                {t("home.howItWorks.option3")}
              </div>
              <div className={styles.description}>
                {t("home.howItWorks.option3Description")}
              </div>
            </div>
            <div className={styles.sectionListImgs}>
              <img src={vector4} className={styles.iconMob} alt="icon" />
              <img src={vector1} className={styles.icon} alt="icon" />
              <img src={icon3} className={styles.icon} alt="icon" />
              <img src={vector2} className={styles.iconDesc} alt="icon" />
            </div>
          </div>

          <div className={styles.sectionListItem}>
            <div className={styles.sectionListText}>
              <div className={styles.title}>
                <div className={styles.sectionListNumber}>
                  <span>4</span>
                </div>
                {t("home.howItWorks.option4")}
              </div>
              <div className={styles.description}>
                {t("home.howItWorks.option4Description")}
              </div>
            </div>
            <div className={styles.sectionListImgs}>
              <img src={vector5} className={styles.iconMob} alt="icon" />
              <img src={vector1} className={styles.icon} alt="icon" />
              <img src={icon4} className={styles.icon} alt="icon" />
              <img src={vector3} className={cx(styles.iconDesc, styles.iconDescLeft)} alt="icon"/>
            </div>
          </div>

          <div className={styles.sectionListItem}>
            <div className={styles.sectionListText}>
              <div className={styles.title}>
                <div className={styles.sectionListNumber}>
                  <span>5</span>
                </div>
                {t("home.howItWorks.option5")}
              </div>
              <div className={styles.description}>
                {t("home.howItWorks.option5Description")}
              </div>
            </div>
            <div className={styles.sectionListImgs}>
              <img src={vector5} className={styles.iconMob} alt="icon" style={{display: "none"}}/>
              <img src={vector1} className={styles.icon} alt="icon" />
              <img src={icon5} className={styles.icon} alt="icon" />
              <img src={vector2} className={styles.iconDesc} alt="icon"  style={{display: "none"}}/>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
