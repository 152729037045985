import cx from 'classnames';
import { FastField, Field, Form, withFormik } from 'formik';
import React, { useContext, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';
import { AffiliateContext } from "../../contexts/AffiliateContext"

import { formatPhoneNumber, getCookie, getReferrer, getUTM } from '../../helpers';
import { useLangContext } from '../../utils/lang';
import { Button2 } from '../button2';
import contactFormStyles from './contact-form-new.module.css';
import styles from './form.module.css';
import { PhoneErrorMessage } from './forms-error-messages';
import { MaskedPhoneInput } from './phone';

const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

Yup.addMethod(Yup.string, "phone", function() {
  return this.test("phone", "Telefonní číslo musí obsahovat 12 znaků", value =>
    rePhoneNumber.test(value)
  )
});


export const ContactFormLayout = ({
  isSubmitting,
  values,
  errors,
  touched,
  setFieldValue,
  btnType = "secondary",
  btnText = "Jetzt bestellen",
  form_name = "hromadna-objednavka",
}) => {
  const { t } = useTranslation()
  const affiliateContext = useContext(AffiliateContext)
  const { isAffiliate, affiliateId } = affiliateContext.affiliate

  const phoneRef = useRef();

  const handleChange = () => {
    setFieldValue("isAffiliate", isAffiliate);
    setFieldValue("affiliateId", affiliateId);
  }

  useEffect(()  => {
    handleChange();
  }, [affiliateContext, isAffiliate, affiliateId])

  return (
    <Form
      name={form_name}
      data-boom
      method="post"
      className={cx(contactFormStyles.contactForm)}
    >
      <div className={cx(styles.inputField, contactFormStyles.inputField)}>
        {/* <FastField
          component="input"
          type="text"
          name="phone"
          className={cx(styles.input, contactFormStyles.input)}
        /> */}
        <MaskedPhoneInput
          innerRef={phoneRef}
          isWhite={true}
          isLg={true}
          name="phone"
          value={values.phone}
          error={errors.phone}
          onChange={(value) => {
              setFieldValue("phone", value)
            }
          }
          placeholder={t("forms.onlineOrderFormNumberLabel")}
        />
        {touched.phone && errors.phone && (
          <span className={cx(styles.error, contactFormStyles.error)}>
            {errors.phone}
          </span>
        )}
      </div>
      <Button2
        color="secondary"
        buttonType="submit"
        disabled={isSubmitting}
        className={contactFormStyles.contactFormButton}
      >
        {btnText || t("forms.hero2FormCTA")}
      </Button2>
      <Field
        component="input"
        type="hidden"
        name="isAffiliate"
        value={isAffiliate}
        onChange={handleChange}
      />
      <Field
        component="input"
        type="hidden"
        name="affiliateId"
        value={affiliateId}
        onChange={handleChange}
      />
    </Form>
  )
}

export const ContactFormNew = withFormik({
  mapPropsToValues: props => ({
    phone: "+49",
    referrer: "",
    ga: "",
    success: false,
    form_name: props.form_name,
    isAffiliate: false,
    affiliateId: ""
  }),
  validationSchema: () =>
    Yup.object().shape({
      phone: Yup.string()
        .min(12, <PhoneErrorMessage />)
        .phone()
        .typeError('A number is required'),
    }),
  handleSubmit: async (
    { phone, form_name, isAffiliate, affiliateId },
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    try {
      const UTMS = getUTM()
      let referrer = getReferrer()

      const isEn = document.location.pathname.includes("/en")
      const testovani = localStorage.getItem("PUSHTELL-homepage")

      const getFormName = () => {
        let formName
        if (form_name) {
          formName = isEn ? `${form_name}_en` : form_name
        } else {
          formName = isEn ? `hromadna-objednavka_en` : `hromadna-objednavka`
        }
        return formName
      }

      const data = {
        form_name: getFormName(),
        language: isEn ? "EN" : "DE",
        region: process.env.GATSBY_COUNTRY,
        branchId: process.env.GATSBY_BRANCH_ID,
        phone: formatPhoneNumber(phone),
        cjevent: affiliateId,
        utm_source: UTMS.UTM_SOURCE,
        utm_medium: UTMS.UTM_MEDIUM,
        utm_campaign: UTMS.UTM_CAMPAIGN,
        utm_term: UTMS.UTM_TERM,
        utm_content: UTMS.UTM_CONTENT,
        referrer: referrer,
        roistat: getCookie("roistat_visit"),
        ga: getCookie("_ga"),
        testovani: testovani,
      }

      console.log("contact-form-new data", data);

      // console.log("contact-form-new data isAffiliate before sending", isAffiliate, "affiliate id ", affiliateId);

      const req = await fetch(`${process.env.GATSBY_APP_API_URL}/application`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
      if (req.ok) {
        await setSubmitting(false)
        await setFieldValue("success", true)
        const response = await req.json()

        console.log("contact-form-new data response", response);
        console.log("contact-form-new data isAffiliate", isAffiliate, "affiliate id ", affiliateId);
        if (isAffiliate) {
          const getParamsData = Object.entries(response)
            .map(([key, value]) => `${key}=${value || 0}`)
            .reduce(
              (previousValue, currentValue) =>
                `${previousValue}&${currentValue}`
            )
          console.log("getParamsData", getParamsData);
          window.location.href = isEn
            ? `/en/thank-you-affiliate/?${getParamsData}&actionTrackerId=433428`
            : `/thank-you-affiliate/?${getParamsData}&actionTrackerId=433428`
        } else {
          window.location.href = isEn
            ? "/en/thank-you"
            : "/thank-you"
        }

        setTimeout(() => {
          resetForm()
          window.dataLayer.push({
            event: "ga.pageview",
            pageURL: isEn
              ? "/en/thank-you-contact-new"
              : "/thank-you-contact-new",
            pageType: "Purchase",
            testovani: testovani,
          })
        }, 300)
      }
    } catch (err) {
      setSubmitting(false)
      setFieldValue("success", false)
      alert("Something went wrong, please try again!")
    }
  },
})(ContactFormLayout)
